import type { $Fetch } from 'nitropack';

import type { Address } from '~/models';

import type { DefaultFilter } from './filter';

export default class AddressService {
  constructor(private fetch: $Fetch) {}

  async delete(uuid: string): Promise<void> {
    return await this.fetch(`/addresses/${uuid}`, {
      method: 'DELETE'
    });
  }

  async list(): Promise<Address[] | null> {
    const store = useAuthStore();

    return await this.fetch<Address[] | null>('/addresses/', {
      query: {
        userId: store.profile?.userId ?? 0
      }
    });
  }

  async post(address: Partial<Address>): Promise<Address | null> {
    const store = useAuthStore();

    address.userId = store.isGuest ? 1 : store.profile?.userId;
    return await this.fetch<Address | null>('/addresses/', {
      method: 'POST',
      body: address
    });
  }

  async put(address: Partial<Address>): Promise<Address | null> {
    return await this.fetch<Address | null>(`/addresses/${address.uuid}`, {
      method: 'PUT',
      body: address
    });
  }
}

export type { DefaultFilter as Filter };
