import type { PaymentIntent, PaymentMethod } from '@stripe/stripe-js';
import type { $Fetch } from 'nitropack';

import type { CardPayment } from '~/models';

const convertPaymentIntent = (paymentIntent: PaymentIntent): PaymentIntent => {
  return {
    ...paymentIntent,
    client_secret: paymentIntent['clientSecret'],
    canceled_at: paymentIntent['canceledAt'],
    cancellation_reason: paymentIntent['cancellationReason'],
    capture_method: paymentIntent['captureMethod'],
    confirmation_method: paymentIntent['confirmationMethod'],
    last_payment_error: paymentIntent['lastPaymentError'],
    next_action: paymentIntent['nextAction'],
    payment_method: paymentIntent['paymentMethod'],
    payment_method_types: paymentIntent['paymentMethodTypes'],
    receipt_email: paymentIntent['receiptEmail'],
    setup_future_usage: paymentIntent['setupFutureUsage']
  };
};

export default class StripeService {
  constructor(private fetch: $Fetch) {}

  async confirmIntent(paymentId: string): Promise<PaymentIntent | null> {
    const response = await this.fetch<PaymentIntent | null>(
      `/stripe/confirmintent/${paymentId}`,
      {
        method: 'POST'
      }
    );

    if (!response) {
      return null;
    }

    return convertPaymentIntent(response);
  }

  async createCustomer(name: string, email: string): Promise<string> {
    return await this.fetch<string>('/stripe/newcustomer', {
      method: 'POST',
      body: { name, email }
    });
  }

  async createIntent(cardPayment: CardPayment): Promise<PaymentIntent | null> {
    const response = await this.fetch<PaymentIntent | null>(
      '/stripe/createintent',
      {
        method: 'POST',
        body: cardPayment
      }
    );

    if (!response) {
      return null;
    }

    return convertPaymentIntent(response);
  }

  async cancelIntent(paymentId: string, reason: string): Promise<void> {
    await this.fetch(`stripe/cancelintent/${paymentId}`, {
      method: 'POST',
      body: reason
    });
  }

  async delete(cardId: string): Promise<string | null> {
    return await this.fetch<string | null>(`/stripe/removecard/${cardId}`, {
      method: 'POST'
    });
  }

  async publishableKey(): Promise<string> {
    return (await this.fetch<string | null>('/stripe/publishkey')) ?? '';
  }

  async paymentMethods(): Promise<PaymentMethod[]> {
    const store = useAuthStore();

    interface Response {
      object: string;
      data: PaymentMethod[];
      has_more: boolean;
      url: string;
    }

    const response = await this.fetch<Response | null>(
      `/stripe/customercards/${store.profile?.stripeId}`
    );

    return response?.data ?? [];
  }
}
