import type { $Fetch } from 'nitropack';

import type { Order } from '~/models';

import type { DefaultFilter } from './filter';

export default class OrderService {
  constructor(private fetch: $Fetch) {}

  async count(filter?: DefaultFilter): Promise<number | null> {
    const store = useAuthStore();

    return await this.fetch<number>('/orders/count', {
      query: {
        userId: store.profile?.userId ?? 0,
        ...filter
      }
    });
  }

  async get(uuid: string): Promise<Order | null> {
    return await this.fetch<Order>(`/orders/${uuid}`);
  }

  async list(filter?: DefaultFilter): Promise<Order[] | null> {
    const store = useAuthStore();

    return await this.fetch<Order[]>('/orders/', {
      query: {
        userId: store.profile?.userId ?? 0,
        sortBy: 'date',
        desc: true,
        page: 1,
        limit: 10,
        ...filter
      }
    });
  }

  async post(order: Partial<Order>): Promise<Order | null> {
    return await this.fetch<Order | null>('/orders/', {
      method: 'POST',
      body: order
    });
  }

  async put(order: Partial<Order>): Promise<Order | null> {
    return await this.fetch<Order | null>(`/orders/${order.uuid}`, {
      method: 'PUT',
      body: order
    });
  }
}

export type { DefaultFilter as Filter };
