export default defineAppConfig({
  ui: {
    primary: 'blue',
    gray: 'cool',
    button: {
      variant: {
        ghost: 'hover:bg-card-contrast/10'
      }
    },
    card: {
      background: 'bg-card border border-card',
      divide: 'divide-transparent',
      ring: ''
    },
    checkbox: {
      base: 'checked:bg-checkbox',
      background: 'bg-transparent',
      border: 'border-card',
      ring: 'focus:ring-company-1',
      label: 'text-inherit'
    },
    formGroup: {
      label: { base: 'text-inherit' },
      hint: 'subtext-card text-xs',
      help: 'mt-2 subtext-card text-xs',
      error: 'mt-2 text-red-500 text-xs'
    },
    input: {
      wrapper: 'bg-input',
      placeholder: 'placeholder:input-card',
      color: {
        white: {
          outline: 'bg-transparent ring-card focus:ring-company-1'
        }
      },
      icon: {
        base: 'subtext-card'
      }
    },
    modal: {
      fullscreen: 'w-dvw h-dvh',
      rounded: '',
      background: '',
      shadow: '',
      overlay: {
        background: 'bg-gray-900/75'
      }
    },
    notifications: {
      position: 'top-0 bottom-auto'
    },
    select: {
      wrapper: 'bg-select',
      placeholder: 'placeholder-input-card',
      color: {
        white: {
          outline: 'bg-transparent ring-card focus:ring-company-1'
        }
      },
      icon: {
        base: 'subtext-card'
      }
    },
    selectMenu: {
      background: 'bg-card',
      ring: 'ring-1 ring-card',
      option: {
        color: 'text-card',
        active: 'bg-card-contrast ring-1 ring-card',
        icon: {
          base: 'text-inherit'
        },
        selectedIcon: {
          base: 'text-inherit'
        }
      }
    },
    skeleton: {
      background: 'bg-card-contrast ring-1 ring-card'
    },
    slideover: {
      overlay: {
        background: 'bg-gray-900/75'
      }
    },
    tabs: {
      list: {
        rounded: '',
        padding: '',
        background: '',
        marker: {
          wrapper: 'border-b-2',
          background: ''
        },
        tab: {
          active: 'text-color-company-1',
          background: '',
          height: 'h-full',
          rounded: '',
          size: 'text-md',
          padding: ''
        }
      }
    },
    textarea: {
      wrapper: 'bg-textarea',
      placeholder: 'placeholder:input-card',
      color: {
        white: {
          outline: 'bg-transparent ring-card focus:ring-company-1'
        }
      },
      icon: {
        base: 'subtext-card'
      }
    }
  }
});
