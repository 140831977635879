import type { Preference } from '~/models';

import { useNuxtApp } from '#app';
import { useSchedule } from '~/composables/useSchedule';

const usePreferenceStore = defineStore('customer-preference', () => {
  const { $http } = useNuxtApp();

  const preference = ref<Preference>({
    general: {
      countryCode: 'IE'
    },
    company: {},
    coverArea: {},
    deliveryTimes: {
      monday: { start: '08:00', end: '23:59' },
      tuesday: { start: '08:00', end: '23:59' },
      wednesday: { start: '08:00', end: '23:59' },
      thursday: { start: '08:00', end: '23:59' },
      friday: { start: '08:00', end: '23:59' },
      saturday: { start: '08:00', end: '23:59' },
      sunday: { start: '08:00', end: '23:59' }
    },
    openingTimes: {
      monday: { start: '08:00', end: '23:59' },
      tuesday: { start: '08:00', end: '23:59' },
      wednesday: { start: '08:00', end: '23:59' },
      thursday: { start: '08:00', end: '23:59' },
      friday: { start: '08:00', end: '23:59' },
      saturday: { start: '08:00', end: '23:59' },
      sunday: { start: '08:00', end: '23:59' }
    },
    template: {}
  });

  const getServiceCharge = async (): Promise<number> => {
    return await $http.preference.getServiceCharge();
  };

  const load = async (): Promise<void> => {
    const response = await $http.preference.get();

    preference.value = response ?? preference.value;
  };

  const moneySymbol = computed<string>(() => {
    return preference.value.general.currency?.symbol ?? '€';
  });

  const isRestaurantOpened = computed<boolean>(() => {
    if (!preference.value.general.online) {
      return false;
    }

    return useSchedule(preference.value.openingTimes).inService();
  });

  const inDeliveryTime = computed<boolean>(() => {
    if (!preference.value.general.enableDelivery) {
      return false;
    }

    return useSchedule(preference.value.deliveryTimes).inService();
  });

  const openAt = computed<string>(() => {
    return useSchedule(preference.value.openingTimes).todayTime();
  });

  const deliveryStartsAt = computed<string>(() => {
    return useSchedule(preference.value.deliveryTimes).todayTime();
  });

  const canonicalURL = computed<string>(() => {
    let link = preference.value.company.websiteUrl;

    if (!link) {
      return '';
    }

    if (!/^https?:\/\//i.test(link)) {
      link = 'https://' + link;
    }

    if (link.endsWith('/welcome')) {
      link = link.replace('/welcome', '');
    }

    return link;
  });

  return {
    preference,
    load,
    getServiceCharge,
    canonicalURL,
    moneySymbol,
    isRestaurantOpened,
    inDeliveryTime,
    openAt,
    deliveryStartsAt
  };
});

export { usePreferenceStore };
