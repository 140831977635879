<script setup lang="ts">
import { useRouter } from 'vue-router';

import type { NuxtError } from '#app';

import Error404 from './components/error/404.vue';
import Error500 from './components/error/500.vue';

const props = defineProps({
  // eslint-disable-next-line vue/require-default-prop
  error: Object as () => NuxtError
});

const router = useRouter();

const onRefresh = (): void => {
  window.location.reload();
};

const onGoHome = async (): Promise<void> => {
  await router.push({ name: 'index' });
};

const onGoBack = (): void => {
  router.back();
};
</script>

<template>
  <div class="w-dvw h-dvh">
    <Error404
      v-if="props.error?.statusCode === 404"
      @go-home="onGoHome()"
      @go-back="onGoBack"
    />
    <Error500
      v-else-if="props.error?.statusCode === 500"
      @refresh="onRefresh"
    />
  </div>
</template>
